import React, {
  Component, StrictMode, useState, useMemo, useCallback,
} from 'react';

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import { Card, Spinner, Button, CardTitle, ButtonGroup, CardBody, CardSubtitle, CardText, Container, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Row, Col, Table, Label, Alert, Form, FormGroup, Input } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import "ag-grid-charts-enterprise";
import { LicenseManager } from 'ag-grid-enterprise'

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';
import { get, post } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// availity-reactstrap-validation
import { AvForm, AvField, AvRadio } from 'availity-reactstrap-validation';
import AddDist from './AddDist';
import FreezeModal from './FreezeModal';
import UpgradeSub from './UpgradeSub';

const GridExample = (props) => {
  const [rowData, setRowData] = useState([]);
  const [allSubs, setAllSubs] = useState([]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const[activateActions,setActivateActions]=useState(false);

  const [selectedData,setSelectedData]=useState({})
  LicenseManager.setLicenseKey("ASWEEDRSSSSSSSSWDEWWDWDWD")
  const [modal, setModal] = useState(false);
  const [freezemodal, setFreezemodal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleFreeze = () => setFreezemodal(!freezemodal);
  const toggleUpgrade = () => setUpgradeModal(!upgradeModal);

  const gridRef = useRef();
  // Row Data: The data to be displayed.
  useEffect(() => {
    setRowData(props.data)
  });

  const LinkCellRenderer = (props) => {
    const { value } = props;
    const url = `/subscribe/view/${value}`; // Construct your URL here

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  };

  const WalletRenderer = (props) => {
    const { data } = props;
    const filteredData = data.filter(item => item.walletType === props.walletName);

    return (
      <div>
        {filteredData.length > 0 && filteredData[0].balance}
      </div>
    );
  };
  const KycRenderer = (props) => {
    const { data } = props;
    const filteredData = data.filter(item => item.walletType === props.walletName);
    console.log(props, filteredData);

    return (
      <div>
        {filteredData.length > 0 && filteredData[0].balance}
      </div>
    );
  };
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleView = (row) => {
    console.log(row)
    // Handle the view action
    alert(`Viewing details of ${row.id}`);
  };

  const handleAdd = (row) => {
    // Handle the add action
    alert(`Adding ${row.name}`);
  };

  const refreshUsers = () => {
    props.getAllUsers();
  }
  const onPaginationChanged = () => {
    if (gridApi) {
      const currentPage = gridApi.paginationGetCurrentPage();
      const pageSize = gridApi.paginationGetPageSize();
      //getAllUsers(currentPage, pageSize);
    }
  };

  const handleCheckboxChange = (rowIndex, param) => {
    console.log(rowData, param)

    let rowDataA = [];
    param.api.forEachNode(node => rowDataA.push(node.data));
    console.log(rowDataA)
  };

  const [colDefs, setColDefs] = useState([
    { field: "mobile", headerName: 'Phone', filter: "agMultiColumnFilter", checkboxSelection: true },
    { field: "name", headerName: 'User Name' },
    { field: "cityPinCode", headerName: 'PIN CODE' },
    {
      field: "userType", headerName: 'Role',sortable: true
     
    },

    { field: "status", headerName: 'Status',sortable: true },
    { field: "email", headerName: 'Email' },
    { field: "gender", headerName: 'Gender' },
    {
      field: "wallet", headerName: 'AEPS Bal.',sortable: true , cellRenderer: (params) => (
        <div className='col-md-5'>
          <WalletRenderer data={params.data.wallet} walletName={'AEPS'} />
        </div>
      ),
    },
    {
      field: "wallet", headerName: 'DMT Bal.', sortable: true,cellRenderer: (params) => (
        <div className='col-md-5'>
          <WalletRenderer data={params.data.wallet} walletName={'DMT'} />
        </div>
      ),
    },
    { field: "gstNumber", headerName: 'GST' },
    { field: "alternateMobile", headerName: 'Alt Mob' },
    { field: "shopAddress", headerName: 'Address' },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <div className='col-md-5'>
           {params.data.status==='ACTIVE'? <Button outline onClick={() => handleView(params.data)} style={{ marginRight: '10px' }}>Disable</Button>:''}
          
          {/* <Button outline onClick={() => handleAdd(params.data)}>
            Lock
          </Button> */}
        </div>
      ),
    },

  ]);
  const onFilterTextBoxChanged = useCallback(() => {
    let datalist = props.data;
    let query = document.getElementById("filter-text-box").value;
    console.log(datalist, query)
    let datafiltered = datalist.filter(item =>
      Object.values(item).some(
        value => value != null && value.toString().toLowerCase().includes(query.toLowerCase())
      )
    );
    console.log(datafiltered)
    if (gridRef) {
      gridRef.current.api.setRowData(datafiltered);  // Update the grid's data

    }

  }, []);
  const onRowSelected = (event) => {
    setActivateActions(true)
    const selectedNode = event.node;
    const selectedData = selectedNode.data;
    setSelectedData(selectedData)
    console.log('Selected Node:', selectedNode);
    console.log('Selected Data:', selectedData);
  };
  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    resizable: true,
    wrapText: true,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
  }
  const getRowData = () => {
    let rowData = [];
    gridApi.forEachNode(node => rowData.push(node.data));
    alert(JSON.stringify(rowData))
  }
  function isFirstColumn(
    params
  ) {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  const gridOptions = {
    defaultColDef: {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      checkboxSelection: isFirstColumn,

    },
    suppressRowClickSelection: true,
    groupSelectsChildren: true,
    // debug: true,
    rowGroupPanelShow: "always",
    pivotPanelShow: "always",
    columnDefs: colDefs,


  };

  const onSelectionChanged = useCallback(
    (event) => {
      var rowCount = event.api.getSelectedNodes().length;
        if(rowCount>0){
          setActivateActions(true)
        }else{
          setActivateActions(false)
        }
    },
    [window],
  );

  return (
    <React.Fragment>
      <div className="d-flex p-1 m-1 justify-content-left">
        {modal ? <AddDist modal={modal} toggle={toggle} refreshUsers={refreshUsers} /> : ""}

        {freezemodal ? <FreezeModal selectedData={selectedData} modal={freezemodal} toggle={toggleFreeze} refreshUsers={refreshUsers} /> : ""}

        {upgradeModal ? <UpgradeSub selectedData={selectedData} modal={upgradeModal} toggle={toggleUpgrade} refreshUsers={refreshUsers} /> : ""}

        <Form>
          <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col>
              <UncontrolledDropdown
                className="me-2"
                direction="end"
                disabled={!activateActions}
              >
                <DropdownToggle
                  caret
                  color={!activateActions?"secondary":"primary"}
                >
                  Actions
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleFreeze}>
                    Freeze Amount
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    Block User
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={toggleUpgrade} >
                    Upgrade Subscription
                  </DropdownItem>
                  <DropdownItem>
                   Kyc Details
                  </DropdownItem>
                  <DropdownItem>
                   Wallet
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>

            <Col>
              <Button onClick={toggle}>Add Distributor</Button>
            </Col>

            <Col>
              <Input
              id="filter-text-box"
                type="text"
                onInput={onFilterTextBoxChanged}
                placeholder="Search by Name or Phone..."
                style={{ marginBottom: '10px' }}
              />
            </Col>

            <Col>

            </Col>
          </Row>
        </Form>
      </div>



      <div
        className="ag-theme-alpine" // applying the grid theme
        style={{ height: 800 }} // the grid will fill the size of the parent container
      >



        <br></br>

        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={rowData}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          onPaginationChanged={onPaginationChanged}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}

        />
      </div>

    </React.Fragment>
    // wrapping container with theme & size

  )
}
const UserList = (props) => {

  const [state, setState] = useState({
    username: "admin",
    password: "test123",
    charge: 0.0,
    userType: "",
    data: [],
    errors: [],
    values: [],
  });
  const [loader, setLoader] = useState(false)
  
  useEffect(() => {

    getAllUsers();
  }, []);



  const getAllUsers = async () => {
    try {
      let data = { "pageNo": 0, "pageSize": 10000 }
      setLoader(true)
      const response = await post("/user/search/list", data);
      setLoader(false);
      setState(prevState => ({ ...prevState, data: response.data.content }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>

                {loader ? <div className="loader-container text-center">
                  <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader Spinner */}
                </div> : ""}

                <Row>
                  {state.data.length > 0 ? <GridExample data={state.data} getAllUsers={getAllUsers} /> : ''}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(UserList));

